<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Employee Add</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToEmployeeList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_employee_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="employee.first_name"
                    :counter="25"
                    :rules="firstNameRules"
                    label="First Name"
                    required
                    outlined
                    dense
                    class="pa-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pa-2"
                    v-model="employee.last_name"
                    :counter="25"
                    :rules="lastNameRules"
                    label="Last Name"
                    required
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="employee.email"
                    label="Email"
                    :rules="emailRules"
                    required
                    class="pa-2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pa-2"
                    :rules="passwordRules"
                    v-model="employee.password"
                    label="Password"
                    :append-icon="pass_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="pass_show ? 'text' : 'password'"
                    @click:append="pass_show = !pass_show"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="employee.department"
                    label="Department"
                    required
                    class="pa-2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pa-2"
                    :rules="cPasswordRules"
                    v-model="employee.cpassword"
                    label="Confirm Password"
                    :append-icon="cpass_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="cpass_show ? 'text' : 'password'"
                    @click:append="cpass_show = !cpass_show"
                    v-validate="'required|confirmed:password'"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                
              </v-row>
              <v-row no-gutters>
              <v-col cols="12" sm="6">
                  <v-select
                    class="pa-2"
                    v-model="employee.role_id"
                    :items="role_list"
                    label="Role"
                    dense
                    outlined
                  >
                  </v-select>
              </v-col>
              </v-row>
              <v-row>
                <v-btn
                  v-if="edit_form"
                  :disabled="!valid || disable_btn"
                  class="ml-5"
                  color="success"
                  ref="kt_store_submit"
                  @click="validateEditForm"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="ml-5"
                  ref="kt_store_submit"
                  @click="validate"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Employees-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  STORE_EMPLOYEE,
  UPDATE_EMPLOYEE
} from "@/core/services/store/employee.module";
import EventBus from "@/core/services/store/event-bus";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "employeesCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      employee: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        cpassword: "",
        department: "",
        user_type: 3,
        is_chapter_user: 0,
        role_id:""
      },
      valid: true,
      disable_btn: false,
      pass_show: false,
      cpass_show: false,
      firstNameRules: [
        v => !!v || "First Name is required",
        v =>
          (v && v.length <= 25) || "First Name must be less than 25 characters"
      ],
      lastNameRules: [
        v => !!v || "Last Name is required",
        v =>
          (v && v.length <= 25) || "Last Name must be less than 25 characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(v) ||
          "E-mail must be valid"
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v =>
          (v && v.length <= 20) || "Password must be less than 25 characters",
        v =>
          (v && v.length >= 6) || "Password must be greater than 5 characters"
      ],
      cPasswordRules: [
        v => !!v || "Password is required",
        v =>
          (v && v.length <= 20) || "Password must be less than 25 characters",
        v =>
          (v && v.length >= 6) || "Password must be greater than 5 characters",
        v =>
          (this.employee.password === this.employee.cpassword) || "Confirm Password must be same as Password"
      ],
      edit_form: false,
      role_list: [],
      company_type: "Small Enterprise"
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getCompany"])
  },
  watch: {
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "employee/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.employee) {
            context.employee = result.data.employee;
            context.employee.department = context.employee.role;
            context.employee.role_id = result.data.employee.role_id.toString();
            context.employee.password = "******";
            context.employee.cpassword = "******";
          } else {
            context.$router.go(-1); //go back to list
            Swal.fire(result.data.error, "", "error");
          }
        },
        function(error) {
          Swal.fire("Error", "Employee not found.", "error");
          context.$router.push({ name: "employeesList" });
        }
      );
    }

    EventBus.$on("STORE_EMPLOYEE_BUS", function(payLoad) {
      if (payLoad) {
        context.$router.push({ name: "employeesList" });
        // Swal.fire("Success", "Contact us stored successfully.", "success");
      } else {
        context.disable_btn = false;
        // Swal.fire("Error", "Error in storing contact us.", "error");
      }
    });

    EventBus.$on("UPDATE_EMPLOYEE_ERROR", function(newVal) {
        context.$router.push({ name: "employeesList" });
    });

    if (this.getCompany && this.getCompany.length > 0) {
      this.company_type = this.getCompany.company_type;
    } else {
      this.$store.dispatch(GET_COMPANY).then(() => {
        this.company_type = this.getCompany.company_type;
      });
    }

    context.getRoleList();
    
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Employee" }]);

    const employee_form = KTUtil.getById("kt_employee_form");

    this.fv = formValidation(employee_form, {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getRoleList() {
      let context = this;
      axios({
        method: "get",
        url: "committee-roles",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          result.data.committee_roles.forEach(role => {
            context.role_list.push({text: role.name, value: (role.id).toString()});
          });
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Committee roles list",
            "info"
          );
        }
      );
    },
    updateEmployee() {
      let context = this;
      this.$store.dispatch(UPDATE_EMPLOYEE, this.employee).then(() => {
        this.$router.go(-1);
        context.disable_btn = false;
        // Swal.fire("Employee updated successfully", "", "success");
      });
    },
    redirectToEmployeeList() {
      let context = this;
      // context.$router.go(-1);
      context.disable_btn = false;
      this.$router.push({ name: "employeesList" });
    },
    submitEmployee() {
      if (this.company_type == "Chapter Organization") {
        this.employee["is_chapter_user"] = 1;
      }
      this.$store.dispatch(STORE_EMPLOYEE, this.employee);
      // .then(() => {
      //   // Swal.fire("Employee added successfully", "", "info");
      // })
      // .then(() => {
      // });
    },
    validateEditForm() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.updateEmployee();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.submitEmployee();
      } else {
        this.snackbar = true;
      }
    }
  }
};
</script>
